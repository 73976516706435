<template>
  <div class="content img">
    <img class="image" :src="src + `.jpg`" :alt="alt" />
    <div class="text-container">
      <div
        v-for="string in text"
        :key="string"
        class="has-text-centered is-italic"
      >
        {{ string }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['src', 'alt', 'text']
}
</script>

<style lang="scss">
.img {
  .image {
    border-radius: 6px;
  }
  .text-container {
    padding: 1rem;
    font-style: italic;
    font-size: 0.85rem !important;
  }
}
</style>
