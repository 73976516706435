<template>
  <div>
    <div class="columns">
      <div class="column">
        <Om />
      </div>
      <div class="column is-one-third has-text-centere has-text-centered">
        <Img
          src="cykel"
          alt="Din personliga redovisningsbyrå"
          :text="[
            'Kristina Nilsson, redovisningskonsult,',
            'på ”tjänstecykeln”'
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Om from '@/components/Om'
import Img from '@/components/Img'

export default {
  components: {
    Om,
    Img
  }
}
</script>
