<template>
  <footer class="footer">
    <div class="columns is-centered">
      <div class="column is-narrow has-text-centered">
        <Phone />
      </div>
      <div class="column is-narrow has-text-centered">
        <Email />
      </div>
      <div class="column is-narrow has-text-centered has-text-primary">
        <span class="icon">
          <i class="mdi mdi-map-marker"></i>
        </span>
        Röinge 5531, 281 37 Hässleholm
      </div>
    </div>
  </footer>
</template>

<script>
import Phone from '@/components/Phone'
import Email from '@/components/Email'

export default {
  components: {
    Phone,
    Email
  }
}
</script>

<style lang="scss">
@import '../assets/sass/buefy';

.footer {
  border-top: 2px solid $primary;
  padding: 1rem 1rem !important;

  .column {
    padding: 0.25rem 1.5rem;
  }
}
</style>
