<template>
  <div class="om">
    <h1>Åsvägens Redovisning AB</h1>

    <p>
      Åsvägens Redovisning ligger i Hässleholm men har ett stort
      upptagningsområde då byrån gärna, utöver traditionell pappersbaserad
      bokföring, även arbetar med digitala underlag. Den huvudsakliga målgruppen
      är små och mellanstora företag men självklart är alla kunder välkomna,
      såväl företag som föreningar och privatpersoner. Oavsett om du precis har
      startat verksamheten, om du sköter bokföringen själv men inte får ihop det
      eller om du redan har hjälp men funderar på att byta så hör gärna av dig
      så vi kan prata om vad som är bäst för just dig.
    </p>

    <p>
      Jag som driver byrån heter Kristina Nilsson, jag är utbildad ingenjör och
      redovisningskonsult. En av mina viktigaste målsättningar med byrån,
      förutom att självklart driva en kompetent och serviceinriktad
      redovisningsbyrå, är att det ska vara en lite mer personlig och trivsam
      redovisningsbyrå. Du som kund ska känna dig prioriterad oavsett
      verksamhetens omfattning, du ska känna dig trygg i att lämna över ditt
      företags ekonomiska hantering och veta att bokföringen sker enligt god
      redovisningssed och uppfyller aktuella regler. Byrån ska vara en
      samarbetspartner snarare än en leverantör och det ska finnas ett
      självklart samarbete där båda parter arbetar aktivt för ditt bästa.
    </p>

    <p>
      Välkommen att höra av dig!
    </p>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>

<style lang="scss" scoped>
.om {
  h1 {
    font-size: 1.3rem !important;
  }

  h2 {
    font-size: 1.05rem !important;
  }

  h1,
  h2,
  p {
    margin-bottom: 1rem;
  }
}
</style>
