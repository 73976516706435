<template>
  <div>
    <div class="columns">
      <div class="column">
        <Tjanster />
      </div>
      <div class="column is-one-third has-text-centere has-text-centered">
        <TjansterBubbles />
        <Img
          src="hus"
          alt="Åsvägens Redovising AB hus i Hässleholm"
          :text="[
            'Åsvägens Redovisning har kontor i södra Hässleholm, i korsvirkeshuset i svängen'
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tjanster from '@/components/Tjanster'
import Img from '@/components/Img'
import TjansterBubbles from '@/components/TjansterBubbles'

export default {
  components: {
    Tjanster,
    Img,
    TjansterBubbles
  }
}
</script>
