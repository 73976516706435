<template>
  <div class="hitta columns is-mobile is-multiline is-vcentered">
    <div class="column is-full">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17786.14909822827!2d13.759684914560511!3d56.13516708686517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTbCsDA4JzA2LjYiTiAxM8KwNDYnMzcuOSJF!5e0!3m2!1ssv!2sse!4v1545499177357"
        width="100%"
        height="400"
        frameborder="0"
        style="border:0"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.hitta {
  ul {
    padding: 0 0;
  }
  iframe {
    height: 400px;
  }
}
</style>
