<template>
  <div class="content">
    <section class="bubbles section">
      <div class="columns is-centered is-multiline">
        <div
          class="column is-narrow has-text-centered"
          v-for="tjanst in tjanster"
          :key="tjanst"
        >
          <span class="tjanst has-text-primary has-text-weight-bold">
            {{ tjanst }}
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      tjanster: [
        'Löpande bokföring',
        'Bokslut',
        'Årsredovisningar',
        'Deklarationer',
        'Löner',
        'Rådgivning'
      ]
    }
  }
}
</script>

<style lang="scss">
.bubbles {
  background: lightgray;
  filter: brightness(1.1);
  padding: 1.5rem 1rem;
  border-radius: 6px;

  .tjanst {
    padding: 0px 4px;
    border-radius: 6px;
    font-size: 1.1rem !important;
  }
}
</style>
