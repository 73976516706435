<template>
  <section class="hero">
    <div class="hero-head">
      <Header />
    </div>
    <div class="hero-body">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
    <div class="hero-foot">
      <Foot />
    </div>
  </section>
</template>

<script>
import Header from './components/Header.vue'
import Foot from './components/Foot.vue'

export default {
  name: 'app',
  components: {
    Header,
    Foot
  }
}
</script>

<style lang="scss">
@import 'assets/sass/buefy';

html,
body {
  width: 100%;
  height: 100%;
  font-size: 1.05rem !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bolder !important;
  font-size: 1.3rem !important;
  margin-bottom: 0.25rem !important;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-family: 'Cormorant Garamond', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.hero {
  min-height: 100%;

  .hero-body {
    flex-grow: 999999;

    .container {
      padding: 10px;
    }
  }
}
</style>
