<template>
  <div class="contact">
    <div class="content">
      <h2>Ring på</h2>
      <Phone class="content" />

      <h2>Mejla till</h2>
      <Email class="content" />

      <h2>Hitta hit!</h2>
      <div class="content">
        <div class="has-text-primary" style="padding: 0px;">
          Åsvägens Redovisning AB<br />
          Röinge 5531<br />
          281 37 Hässleholm
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Phone from '@/components/Phone'
import Email from '@/components/Email'

export default {
  components: {
    Phone,
    Email
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/buefy';

.contact {
  .title.is-5 {
    margin-bottom: 0.5rem;
  }
}
</style>
