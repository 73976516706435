<template>
  <div>
    <h1>
      Vill du komma i kontakt med Åsvägens Redovisning?
    </h1>

    <div class="columns is-vcentered">
      <div class="column is-three-fifths">
        <Hitta />
      </div>
      <div class="column is-two-fifths">
        <Kontakt />
      </div>
    </div>
  </div>
</template>

<script>
import Kontakt from '@/components/Kontakt'
import Hitta from '@/components/Hitta'

export default {
  components: {
    Kontakt,
    Hitta
  }
}
</script>
