<template>
  <div class="tjanster">
    <h1>Tjänster</h1>

    <p>
      Åsvägens Redovisning erbjuder alla de traditionella tjänsterna inom
      redovisning, allt från löpande bokföring, bokslut och årsredovisningar
      till löneadministration, rådgivning och deklarationer. Upplägget för ditt
      företag styrs av dina behov. Du kanske har behov av en komplett
      helhetslösning för den ekonomiska hanteringen. Eller behöver du hjälp med
      delar av hanteringen, kanske anlitar du redan en revisor men vill ha hjälp
      av en redovisningskonsult till det löpande arbetet? Vi skräddarsyr ett
      arbetssätt som passar!
    </p>

    <p>
      Föredrar du pappersbaserat eller digitalt?
    </p>

    <p>
      Alla är olika. Vissa föredrar fysiska underlag medan andra vill ha det
      digitalt. Pappersbaserad bokföring, där du som kund lämnar över dina
      fysiska underlag personligen alternativt lämnar dem i brevlådan, är det
      traditionella sättet att hantera underlagen. För en flexibel överlämning
      är brevlådan är tillgänglig dygnet runt och rymmer även större
      försändelser som pärmar.
    </p>
    <p>
      Idag blir det dock vanligare och vanligare att man går över till att
      hantera allting digitalt, där till exempel bokföringsunderlagen skickas
      direkt till bokföringsprogrammet som bildunderlag med hjälp av en app. Det
      finns en mängd flexibla och smarta lösningar som kan skräddarsys utifrån
      behov.
    </p>

    <p>
      Hör gärna av dig för att veta mer eller för att få förslag på bäst upplägg
      för ditt företag!
    </p>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>

<style lang="scss" scoped>
.tjanster {
  p {
    margin-bottom: 1rem;
  }
}
</style>
