<template>
  <div class="content">
    <router-link :to="{ name: 'om' }" alt="Åsvägens Redovisning AB Hem">
      <div class="header" />
    </router-link>
    <nav
      class="navbar has-background-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a
          role="button"
          class="navbar-burger burger has-text-white"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu has-background-primary">
        <div class="container">
          <div class="navbar-start is-family-secondary">
            <router-link class="navbar-item has-text-white" :to="{ name: 'om' }"
              >Om byrån</router-link
            >
            <router-link
              class="navbar-item has-text-white"
              :to="{ name: 'tjanster' }"
              >Tjänster</router-link
            >
            <router-link
              class="navbar-item has-text-white"
              :to="{ name: 'kontakt' }"
              >Kontakt</router-link
            >
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted: function() {
    document.addEventListener('DOMContentLoaded', () => {
      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll('.navbar-burger'),
        0
      )
      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach(el => {
          el.addEventListener('click', () => {
            // Get the target from the "data-target" attribute
            const target = el.dataset.target
            const $target = document.getElementById(target)
            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active')
            $target.classList.toggle('is-active')

            // Close menu on item click
            $target.addEventListener('click', () => {
              el.classList.remove('is-active')
              $target.classList.remove('is-active')
            })
          })
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/buefy';

.header {
  height: 180px;
  background-image: url('/banner.jpg');
  background-size: auto;
  background-position: center;
  padding: 3.5em;
}
.divider {
  height: 15px;
}
.navbar {
  .navbar-start {
    width: 100%;
  }

  font-family: 'calarendon';
  font-size: 1rem !important;
  font-weight: normal;

  .navbar-item {
    &:hover {
      filter: brightness(0.9);
      background-color: initial;
    }
    &:focus {
      background-color: initial;
    }
  }
}
</style>
