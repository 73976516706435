import Vue from 'vue'
import Router from 'vue-router'

import Om from './views/Om.vue'
import Kontakt from './views/Kontakt.vue'
import Tjanster from './views/Tjanster.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/kontakt',
      name: 'kontakt',
      component: Kontakt
    },
    {
      path: '/',
      name: 'om',
      component: Om
    },
    {
      path: '/tjanster',
      name: 'tjanster',
      component: Tjanster
    }
  ]
})
