<template>
  <div>
    <a href="mailto:info@asvagensredovisning.se" class="has-text-primary">
      <span class="icon"><i class="mdi mdi-email"></i></span>
      <span>{{ email }}</span>
    </a>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      email: 'info@asvagensredovisning.se'
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  span:first-of-type {
    margin-left: -4px;
  }
}
</style>
