<template>
  <div>
    <a v-if="isMobile()" href="tel:0705199499" class="has-text-primary">
      <span v-if="hasIcon" class="icon"><i class="mdi mdi-phone"></i></span>
      <span>{{ phone }}</span>
    </a>
    <div v-else class="has-text-primary">
      <span v-if="hasIcon" class="icon"><i class="mdi mdi-phone"></i></span>
      <span>{{ phone }}</span>
    </div>
  </div>
</template>

<script>
const MobileDetect = require('mobile-detect')

export default {
  props: {
    hasIcon: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      phone: '0705 199 499'
    }
  },
  methods: {
    isMobile: function() {
      const md = new MobileDetect(navigator.userAgent)
      return !!md.mobile()
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  span:first-of-type {
    margin-left: -4px;
  }
}
</style>
